import React, { useState } from "react";
import { useFormik } from "formik";
import { FaArrowCircleRight } from "react-icons/fa";

const EmailForm = ({ label = "me@email.com" }) => {
  const form = useFormik({
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "Please fill out this field.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address.";
      }
      return errors;
    },
    onSubmit: (values) => {
      setIsError(false);
      setIsLoading(true);
      sendRequest(values);
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const sendRequest = (values) => {
    const form = new FormData();
    //form.append('first_name', values.first.trim());
    //form.append('last_name', values.last.trim());
    form.append("email", values.email.trim());

    console.log("Submitting:", form);
    const target = "https://hook.us1.make.com/9666ph1i2n85xmqmqhi23p1x6psiog8a";
    fetch(target, { method: "POST", body: form })
      .then(() => handleSuccess())
      .catch((error) => handleError(error));
  };

  const handleSuccess = () => {
    setIsLoading(false);
    setIsSuccess(true);
  };

  const handleError = (error) => {
    console.error("Form submission failed :(", error.message);
    setIsLoading(false);
    setIsError(true);
  };

  return isSuccess ? (
    <div className="">Thanks for signing up!</div>
  ) : (
    <form onSubmit={form.handleSubmit} className="flex space-x-1">
      <div className="w-full">
        <ValidatedField form={form} id="email" label={label} />
      </div>
      <button
        type="submit"
        disabled={isLoading}
        className="text-light hover:text-primary"
        data-analytics-track="Mailing List Subscribed"
      >
        {isLoading ? (
          <FaArrowCircleRight className="animate-spin" />
        ) : (
          <FaArrowCircleRight />
        )}
      </button>
      {/*
      {isError && (
        <div className="">
          <div className="flex items-center justify-center p-3 font-bold rounded bg-red">
            Oops! Something went wrong.
          </div>
          <div className="mt-2 text-sm font-bold">
            Email us at{" "}
            <a href="mailto:hello@hiddendoor.co">hello@hiddendoor.co</a> and
            we'll happily add you to the list!
          </div>
        </div>
      )}
      */}
    </form>
  );
};

const ValidatedField = ({ form, id, label }) => {
  const { getFieldProps } = form;
  const errors = form.errors[id];
  const touched = form.touched[id];
  return (
    <div>
      <input
        {...getFieldProps(id)}
        type="email"
        id={id}
        placeholder={label}
        className={`w-full ${
          errors && touched ? "border-primary" : ""
        }`}
      />
      {errors && touched && (
        <span className="mt-1 text-xs text-primary">{errors}</span>
      )}
    </div>
  );
};

export default EmailForm;
